import axios from 'axios';
import { useEffect, useState } from 'react';
import API_URL, { localStorageName } from './constants';

const auth = localStorage.getItem(localStorageName);

if (!auth && window.location.pathname !== '/login') {
  window.location.href = '/login';
}

const authObject = JSON.parse(auth);

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    authorization: authObject ? `Bearer ${authObject.token}` : ''
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.removeItem(localStorageName);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const useAxiosGet = (url, params, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(url, {
        params: queryParams,
        headers
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return {
    data,
    setData,
    loading,
    error,
    request
  };
};

export const useAxiosDelete = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (deleteId = '') => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(`${url}/${deleteId}`);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    request
  };
};

export const useAxiosPost = (url, body, headers = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, headersParams = headers) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(url, bodyParams);
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    setData,
    loading,
    error,
    request
  };
};

export const useAxiosPut = (url, body, headers = {}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyData = body) => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(url, bodyData);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    request
  };
};

export const useAxiosPatch = (
  url,
  body,
  params = { autoRun: false },
  headers = {}
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (bodyParams = body, queryParams = params) => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(url, bodyParams, {
        params: queryParams,
        headers
      });
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.autoRun) {
      request();
    }
  }, []);

  return {
    data,
    setData,
    loading,
    error,
    request
  };
};

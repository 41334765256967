import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Row, Popconfirm, Button } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

import routes from '../routes';
import { localStorageName } from '../configs/constants';

const { Header, Content } = Layout;

const CustomLayout = ({ setCollapsed, collapsed }) => {
  return (
    <Layout className="site-layout">
      <Header className="header-layout">
        <Row className="menu-header">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed)
            }
          )}
          <Popconfirm
            title="Are you Sure?"
            okText="Yes"
            onConfirm={() => {
              localStorage.removeItem(localStorageName);
              window.location.href = '/login';
            }}
            cancelText="No"
          >
            <Button
              type="danger"
              htmlType="button"
              style={{
                background: 'red',
                color: 'white'
              }}
              icon={<PoweroffOutlined />}
            >
              Logout
            </Button>
          </Popconfirm>
        </Row>
      </Header>
      <Content className="content-routes">
        <Routes>
          {routes.map((route) =>
            route?.children?.length > 1 ? (
              route.children.map((subroute) => (
                <Route
                  path={subroute.path}
                  key={subroute.key}
                  element={
                    <Suspense fallback={<>...</>}>
                      <subroute.component />
                    </Suspense>
                  }
                />
              ))
            ) : (
              <Route
                path={route.path}
                key={route.key}
                element={
                  <Suspense fallback={<>...</>}>
                    <route.component />
                  </Suspense>
                }
              />
            )
          )}
        </Routes>
      </Content>
    </Layout>
  );
};

export default CustomLayout;

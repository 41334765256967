import { lazy } from 'react';
import {
  UserOutlined,
  AccountBookOutlined,
  TagsOutlined,
  HomeOutlined,
  FireOutlined,
  LockOutlined,
  BlockOutlined,
  HddOutlined,
  PicRightOutlined
} from '@ant-design/icons';

const CoreValues = lazy(() => import('./pages/CoreValues'));
const Timelines = lazy(() => import('./pages/Timelines'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const CareersPage = lazy(() => import('./pages/CareersPage'));
const SectorPage = lazy(() => import('./pages/SectorPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Position = lazy(() => import('./pages/Position'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const Tag = lazy(() => import('./pages/Tag'));
const Blog = lazy(() => import('./pages/Blog'));

const routes = [
  {
    key: Math.random(),
    icon: <AccountBookOutlined />,
    label: 'Pages',
    children: [
      {
        key: Math.random(),
        path: '/',
        component: HomePage,
        icon: <HomeOutlined />,
        label: 'Home Page'
      },
      {
        key: Math.random(),
        path: '/about-page',
        component: AboutPage,
        icon: <TagsOutlined />,
        label: 'About Page'
      },
      {
        key: Math.random(),
        path: '/careers-page',
        component: CareersPage,
        icon: <UserOutlined />,
        label: 'Careers Page'
      },
      {
        key: Math.random(),
        path: '/sector-page',
        component: SectorPage,
        icon: <UserOutlined />,
        label: 'Sector Page'
      }
    ]
  },
  {
    key: Math.random(),
    icon: <HddOutlined />,
    label: 'Doctype',
    children: [
      {
        key: Math.random(),
        path: '/position',
        component: Position,
        icon: <UserOutlined />,
        label: 'Position'
      },

      {
        key: Math.random(),
        path: '/blog',
        component: Blog,
        icon: <UserOutlined />,
        label: 'Blogs'
      }
    ]
  },
  {
    key: Math.random(),
    icon: <FireOutlined />,
    label: 'Policy',
    children: [
      {
        key: Math.random(),
        path: '/privacy',
        component: PrivacyPage,
        icon: <LockOutlined />,
        label: 'Privacy'
      },

      {
        key: Math.random(),
        path: '/terms-and-conditions',
        component: TermsAndConditions,
        icon: <BlockOutlined />,
        label: 'Terms & Conditions Page'
      }
    ]
  },

  {
    key: Math.random(),
    icon: <PicRightOutlined />,
    label: 'Related Info',
    children: [
      {
        key: Math.random(),
        path: '/core-values',
        component: CoreValues,
        icon: <UserOutlined />,
        label: 'Core Values'
      },
      {
        key: Math.random(),
        path: '/timelines',
        component: Timelines,
        icon: <UserOutlined />,
        label: 'Timelines'
      },
      {
        key: Math.random(),
        path: '/tag',
        component: Tag,
        icon: <UserOutlined />,
        label: 'Tags'
      }
    ]
  }
];
export default routes;

/* eslint-disable no-restricted-globals */
import { Button, Form, Input, notification } from 'antd';
// import Wave from 'react-wavify';
import React from 'react';

import useLocalStorage from '../configs/localStorage';
import '../assets/styles/pages/login.scss';
import logo from '../assets/images/logo.png';
import logIn from '../assets/images/login.gif';
import { useAxiosPost } from '../configs/axios';
import { Navigate } from 'react-router-dom';
import { localStorageName } from '../configs/constants';

const Login = () => {
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const { request: loginRequest, loading: loginLoading } =
    useAxiosPost('/auth/login');

  const onLogin = async (values) => {
    try {
      const loginData = await loginRequest({
        username: values.username,
        password: values.password
      });

      setAuth({
        token: loginData.token,
        user: loginData.user
      });
      location.replace('/');
    } catch (error) {
      notification.error({
        message: "Can't login",
        description: error.response.data.message,
        placement: 'bottomRight'
      });

      console.log('🚀 ~ file: Login.jsx:27 ~ onLogin ~ error', error);
    }
  };
  if (auth) {
    return <Navigate to="/" />;
  }

  return (
    <main className="main-login">
      <img alt="lucid" src={logo} />
      <div className="form-container">
        <div className="form">
          <h1
            style={{
              fontSize: '40px',
              fontWeight: 'bold'
            }}
          >
            Login
          </h1>
          <p
            style={{
              fontWeight: 'bold',
              opacity: 0.5,
              fontSize: '20px'
            }}
          >
            Enter your credentials
          </p>
          <Form
            layout="vertical"
            style={{
              width: '100%',
              marginTop: '50px'
            }}
            onFinish={onLogin}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input size="large" placeholder="Username" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
              name="password"
            >
              <Input.Password
                size="large"
                placeholder="Password"
                type="password"
              />
            </Form.Item>

            <Button
              type="primary"
              size="large"
              style={{
                width: '100%',
                borderRadius: '40px',
                backgroundColor: '#2d3546'
              }}
              loading={loginLoading}
              htmlType="submit"
            >
              Login
            </Button>
          </Form>
        </div>
        <div>
          <img src={logIn} alt="" />
        </div>
      </div>
    </main>
  );
};

export default Login;

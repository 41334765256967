import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { DropboxOutlined } from '@ant-design/icons';

import routes from './../routes';
import clientLogo from './../assets/images/logo.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ collapsed }) => {
  return (
    <Sider trigger={null} collapsible collapsed={collapsed} className="sidebar">
      <div className="logo-container">
        <img className="logo" src={clientLogo} alt="client logo" />
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
        {routes.map((item, i) =>
          item?.children?.length > 1 ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              <Menu.ItemGroup>
                {item.children.map((subitem) => (
                  <Menu.Item key={subitem.key}>
                    {subitem.icon}
                    <span>{subitem.label}</span>
                    <NavLink to={subitem.path} />
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </SubMenu>
          ) : (
            <Menu.Item key={i} title={item.label}>
              <NavLink to={item.path}>
                <span>{item.icon}</span>
                <span
                  style={{
                    visibility: collapsed ? 'hidden' : 'visible'
                  }}
                >
                  {item.label}
                </span>
              </NavLink>
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};

export default SideBar;
